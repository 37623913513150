
 
   @font-face {
     font-family: 'helvRegular'; 
     src: local('helvRegular'), url(./Fonts/HelveticaNowDisplay-Regular.ttf);
   }
 
   @font-face {
     font-family: 'helvMedium';
     src: local('helvMedium'),url(./Fonts/HelveticaNowDisplay-Medium.ttf);
   }
 
   @font-face {
     font-family: 'helvBlack';
     src: local('helvBlack'),url(./Fonts/HelveticaNowDisplay-Black.ttf);
   }
 
   @font-face {
     font-family: 'helvExtraBold';
     src: local('helvExtraBold'),url(./Fonts/HelveticaNowDisplay-ExtraBold.ttf);
   }

   * {
    padding:0; margin: 0;
    font-family: helvMedium;
     }

.carousel {width: 86.2%; max-width: 1184px; margin: 0 auto 124px auto; }
.carousel-control-prev{justify-content: flex-start; opacity: 1; }
.carousel-control-prev img {margin-left: -25px;}
.slide {border-radius: 20px !important;}
.carousel-control-next{justify-content: flex-end;opacity: 1;}
.carousel-control-next img {margin-right: -25px;}
.carousel-indicators{ bottom:-40px; margin:0;}
.carousel-indicators button{overflow: hidden !important; width: 16px !important; height: 16px !important; border: 1px solid #E52232 !important;border-radius: 16px !important;background-color: #E52232 !important; }
.carousel-indicators button.active{width: 72px !important; height: 16px !important;}

@media only screen and (max-width: 500px) {

  .carousel-indicators button{ width: 8px !important; height: 8px !important; border-radius: 8px !important; }
.carousel-indicators button.active{height: 8px !important;}
.carousel {width: 91%; margin: 0 auto 112px auto; }


}